.earning-chart {
  min-width: 354px;
  min-height: 284px;
  padding: 15.5px 18px 17px 20px;
}

.earning-chart h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #363740;
}

.invoice-history table {
  overflow: hidden;
}

.invoice-history tbody {
  color: black;
}

.invoice-history tr {
  cursor: pointer;
}

.invoice-history td {
  white-space: nowrap;
}

.invoice-history thead th {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #676767;
}

.invoice-history tbody td {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */

  letter-spacing: -0.02em;

  /* text_1 */

  color: rgba(0, 0, 0, 0.8);
}

.invoice-history th:first-child {
  padding-left: 30px;
  padding-right: 50px;
}

.invoice-history td:first-child {
  padding-left: 30px;
  padding-right: 50px;
}

.invoice-history .paid {
  color: #009193;
  background: rgba(212, 249, 242, 0.5);
  border-radius: 10px;
  padding-bottom: 2px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  padding-top: 1px;
}

.invoice-history .unpaid {
  color: #e53e3e;
  background: rgba(229, 62, 62, 0.2);
  border-radius: 10px;
  padding-bottom: 2px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  padding-top: 1px;
}

.invoice-history .scheduled {
  color: #f7a666;
  background: rgba(247, 166, 102, 0.2);
  border-radius: 10px;
  padding-bottom: 2px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  padding-top: 1px;
}

.invoice-history .arrow {
  background: rgba(212, 249, 242, 0.5);
  padding: 5px;
  border-radius: 3px;
}

.invoice-history tbody > tr.active td {
  background: #009193cc;
  color: white;
}

.invoice-history .status-active {
  color: white;
  background: rgba(212, 249, 242, 0.5);
  border-radius: 10px;
}

.invoice-history .inner {
  overflow: hidden;
}

.expandable td {
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.2);
  white-space: break-spaces;
}

.expandable .address {
  max-width: 169px;
  background: rgba(212, 249, 242, 0.15);
  border: 1px solid rgba(136, 218, 204, 0.4);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 7px 13px;
}

.expandable .address p:first-child {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #a0aec0;
  margin: 0;
}

.expandable .address p:last-child {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #676767;
  margin-bottom: 0;
}

.inside-table {
  width: 100%;
  background: rgba(212, 249, 242, 0.15);
  border: 1px solid rgba(136, 218, 204, 0.4);
  box-sizing: border-box;
  border-radius: 2px;
}

.expandable .inside-table th {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #a0aec0;
  box-shadow: none;
  padding: 2px 25px;
  text-align: left;
}

.expandable .inside-table td {
  padding: 2px 25px;
  padding-right: 25px;
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  letter-spacing: -0.02em;
  color: rgba(33, 46, 52, 0.8);
  white-space: nowrap;
  box-shadow: none;
}

.expandable .total-amount {
  width: 100%;
  text-align: right !important;
  padding-right: 50px;
  margin-bottom: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #a0aec0;
}

.expandable .total-amount span {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  margin-left: 22px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.8);
}

.business-card {
  min-width: 300px;
  padding: 30px 10px;
}
