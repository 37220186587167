.dropdown-wrap {
  position: relative;
  max-width: 229px;
  width: 229px;
  min-height: 16px;
  margin-bottom: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #a0aec0;
  background-color: white;
}

.dropdown-transfer-wrap {
  position: relative;
  width: 100%;
  min-height: 16px;
  margin-bottom: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #a0aec0;
  background-color: white;
}

#menu-transfer-button {
  z-index: 3;
  width: 100%;
  height: 56px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #a0aec0;
  box-sizing: border-box;
  border-radius: 5px;
  color: #a0aec0;
  background-color: white;
  font-family: 'Roboto';
  font-style: normal;
  color: #768e9c;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 48px 10px 16px;
  margin: 10px auto;
}

.menu-transfer-arrow {
  transition: all 0.05s linear;
  position: absolute;
  right: 25px;
}

.custom-transfer-dropdown-menu {
  width: 100%;
  transition: opacity 0.2s cubic-bezier(0.6, 0.2, 0.1, 1),
    transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1), visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
  transform: none;
  position: absolute;
  top: 57px;
  left: 0;
  z-index: 1000;
  float: left;
  padding: 8px;
  margin: 0;
  list-style: none;
  font-size: 1rem;
  font-size: var(--font-size-16, 1rem);
  text-align: left;
  background-color: #fff;
  background-color: var(--color-background-screen, #fff);
  border: 0 solid rgba(0, 0, 0, 0.10196078431372549);
  border: 0 solid
    var(--color-border-neutral, rgba(0, 0, 0, 0.10196078431372549));
  border-radius: 3px;
  box-shadow: 0 20px 66px 0 rgb(34 48 73 / 20%);
  background-clip: padding-box;
  max-height: 440px;
  overflow-y: scroll;
  border: 1px solid #a0aec0;
  border-radius: 5px;
}

.dropdown-account-wrap {
  position: relative;
  max-width: 400px;
  width: 100%;
  min-height: 16px;
  margin-bottom: 28px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #a0aec0;
  background-color: white;
}

.dropdown-trade-wrap {
  width: 100%;
  min-height: 16px;
}

.dropdown-ledgers-wrap {
  width: 100%;
  font-size: 18px;
}

.dropdown-ledgers-wrap,
.dropdown-trade-wrap {
  position: relative;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #a0aec0;
  background-color: white;
}

#menu-ledger-button {
  font-size: 13px;
}

#menu-trade-button {
  font-size: 15px;
}

#menu-ledger-button,
#menu-trade-button {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  border: none;
  box-sizing: border-box;
  border-radius: 35px;
  color: #a0aec0;
  padding-left: 10px;
  background-color: white;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: #363740;
}

.dropdown-earning-wrap {
  position: relative;
  max-width: 229px;
  width: 90px;
  min-height: 11px;
  margin-bottom: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #939fb5;
  margin-left: 5px;
  background-color: white;
}

.menu-account-item {
  display: flex;
  align-items: center;
  padding: 5px 64px;
  font-size: 1rem;
  font-weight: 400;
  color: #37517e;
  padding: 13px 16px 11px;
}

.menu-account-item:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #37517e;
  border-radius: 5px;
}

.menu-account-item img {
  width: 24px;
  margin-right: 8px;
  margin-top: 4px;
}

.account-input-group {
  position: relative;
  display: flex;
  align-items: center;
  border-collapse: separate;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10196078431372549);
}

.account-input-group-addon {
  padding: 18px 16px 10px 16px;
}

.dropdown-header {
  display: block;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-size: var(--font-size-14, 0.875rem);
  line-height: 1.5;
  line-height: var(--line-height-body, 1.5);
  color: #5d7079;
  color: var(--color-content-secondary, #5d7079);
  white-space: nowrap;
  font-weight: 400;
  margin-bottom: 0;
  padding-bottom: 0;
}

.account-input-group .form-control {
  float: left;
  width: 100%;
  margin-bottom: 0;
  height: auto;
  min-height: 48px;
  max-height: 48px;
  background-color: #fff;
  background-image: none;
  transition: border-color 0.15s ease-in-out;
  padding: 13px 16px 11px;
  font-size: 16px;
  line-height: 24px;
  background-clip: padding-box;
  font-weight: 500;
  color: #37517e;
  border: none;
}

.account-selected-item {
  display: flex;
  align-items: center;
}

.trade-selected-item {
  display: flex;
  align-items: center;
}

.trade-selected-item img {
  width: 34px;
  margin-right: 11px;
}

.account-selected-item img {
  width: 24px;
  margin-right: 8px;
}

#menu-button {
  position: absolute;
  z-index: 3;
  width: 229px;
  height: 33px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #a0aec0;
  box-sizing: border-box;
  border-radius: 10px;
  color: #a0aec0;
  background-color: white;
}

#menu-account-button {
  z-index: 3;
  width: 400px;
  height: 48px;
  display: flex;
  position: relative;
  align-items: center;
  border: 1px solid #a0aec0;
  box-sizing: border-box;
  border-radius: 5px;
  color: #a0aec0;
  background-color: white;
  font-family: 'Roboto';
  font-style: normal;
  color: #768e9c;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 48px 10px 16px;
  margin: 0 auto;
}

#menu-activity-button {
  z-index: 3;
  width: 100%;
  position: relative;
  color: #a0aec0;
  background-color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  width: 80px;
  height: 24px;
  border: 1px solid rgba(160, 174, 192, 0.7);
  box-sizing: border-box;
  border-radius: 5px;
}

.dropdown-activity-wrap {
  position: relative;
  max-width: 80px;
  width: 80px;
  min-height: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #a0aec0;
  background-color: white;
}

.account-text {
  font-family: 'Lato';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #a0aec0;
  letter-spacing: -0.02em;
  margin-bottom: 32px;
}

#menu-earning-button {
  position: absolute;
  z-index: 3;
  width: 90px;
  height: 33px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  color: #a0aec0;
  background-color: white;
}

.account-button {
  width: 400px;
  height: 48px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  border-radius: 5px;
  background: #00a2dd;
  color: white;
  border: none;
}

@media (max-width: 991px) {
  .account-button {
    width: 100%;
  }

  #menu-account-button {
    width: 100%;
  }
}

.flag-img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid blue;
}

.menu-arrow {
  transition: all 0.05s linear;
}

.menu-account-arrow {
  transition: all 0.05s linear;
  position: absolute;
  right: 25px;
}

.toggle-up {
  transform: rotate(180deg);
}

.toggle-down {
  transform: rotate(0deg);
}

.custom-dropdown-menu {
  position: absolute;
  width: 229px;
  z-index: 2;
  top: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #a0aec0;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
}

.custom-dropdown-activity-menu {
  position: absolute;
  width: 80px;
  z-index: 2;
  top: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #a0aec0;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
}

.custom-account-dropdown-menu {
  max-width: 405px;
  width: 110%;
  transition: opacity 0.2s cubic-bezier(0.6, 0.2, 0.1, 1),
    transform 0.2s cubic-bezier(0.6, 0.2, 0.1, 1), visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
  transform: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  float: left;
  padding: 8px;
  margin: 0;
  list-style: none;
  font-size: 1rem;
  font-size: var(--font-size-16, 1rem);
  text-align: left;
  background-color: #fff;
  background-color: var(--color-background-screen, #fff);
  border: 0 solid rgba(0, 0, 0, 0.10196078431372549);
  border: 0 solid
    var(--color-border-neutral, rgba(0, 0, 0, 0.10196078431372549));
  border-radius: 3px;
  box-shadow: 0 20px 66px 0 rgb(34 48 73 / 20%);
  background-clip: padding-box;
  max-height: 440px;
  overflow-y: scroll;
}

.custom-trade-dropdown-menu {
  width: 100%;
}

.custom-ledgers-dropdown-menu {
  width: 100%;
}

.custom-ledgers-dropdown-menu,
.custom-trade-dropdown-menu {
  position: absolute;
  z-index: 2;
  top: 22px;
  right: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 30px;
  background-color: white;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
}

.menu-trade-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.menu-trade-item img {
  width: 34px;
  margin-right: 11px;
}
#coinDropDown {
  height: 0rem;
}
.custom-dropdown-earning-menu {
  position: absolute;
  width: 120px;
  z-index: 2;
  top: 25px;
  left: -15px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
}

.menu-item {
  padding: 10px 5px;
  border-bottom: 1px solid rgba(160, 174, 192, 0.5);
}

.menu-item:last-child {
  padding: 10px 5px;
  padding-bottom: 10px;
  border-bottom: none;
}

.menu-earning-item {
  padding: 7px 0 2px;
  border-bottom: 1px solid rgba(160, 174, 192, 0.5);
}
.menu-earning-item:last-child {
  padding: 7px 0 2px;
  border-bottom: none;
}
