@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 600;
  src: local('Gilroy-Bold'), url('../font/Gilroy-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy-Heavy';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Heavy'), url('../font/Gilroy-Heavy.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy-Light';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Light'), url('../font/Gilroy-Light.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Gilroy-Medium'), url('../font/Gilroy-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Regular'),
    url('../font/Gilroy-Regular.woff') format('woff');
}
body {
  font-family: Gilroy-Regular;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Gilroy-Bold;
}
.font-G-B {
  font-family: gilroy-Bold !important;
}
.font-G-H {
  font-family: Gilroy-Heavy !important;
}
.font-G-L {
  font-family: Gilroy-Light !important;
}
.font-G-M {
  font-family: Gilroy-Medium !important;
}
.font-G {
  font-family: Gilroy-Regular !important;
}

.card-bx .card-info {
  padding: 5px;
}

.card-bx .card-info .dot-img {
  width: 60px;
}

.card-bx .card-info .card-number {
  font-size: 20px;
  font-weight: 400 !important;
}

.card-bx .card-info .card-number.card-number-business {
  font-size: 15px;
  line-height: 1;
}

.card-bx .card-info .iban_number {
  font-size: 22px;
}

.card-bx h3 {
  font-family: 'Gilroy-Medium' !important;
}

.d-grid {
  display: grid;
}

.card-bx .card-info .card-number svg {
  width: 16px;
}

.card-bx .card-info .card-number.card-number-business svg {
  width: 12px;
}

.nav-header {
  border-bottom: 1px solid #f4f7fe;
}
.nav-header .brand-logo {
  background: var(--white);
}

[data-sidebar-style='overlay'] .nav-header .brand-logo {
  background: transparent;
}

[data-sidebar-style='overlay'] .menu-toggle .nav-header .brand-logo {
  background: var(--white);
}

@media (max-width: 1299px) {
  .nav-header .brand-logo {
    background: transparent;
  }
}

@media (max-width: 1199px) {
  .nav-header .brand-logo {
    background: transparent;
  }
}
[data-sidebar-style='full'][data-layout='vertical'] .deznav .metismenu > li {
  padding: 0 20px;
  margin-bottom: 20px;
}

[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li
  > a {
  border-radius: 10px;
  transition: none;
  padding: 9px 12px;
}

[data-sidebar-style='full'][data-layout='vertical']
  .menu-toggle
  .deznav
  .metismenu
  > li {
  margin-bottom: 10px;
}
.deznav {
  transition: none;
}
.deznav .metismenu > li > a svg {
  vertical-align: middle;
  margin-right: 15px;
  color: #009193;
}

.deznav .metismenu > li > a:hover,
.deznav .metismenu > li > a:focus,
[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a {
  background: var(--primary);
  color: #fff !important;
}

.deznav .metismenu > li > a:hover svg,
.deznav .metismenu > li > a:focus svg,
[data-sidebar-style='full'][data-layout='vertical']
  .deznav
  .metismenu
  > li.mm-active
  > a
  > svg {
  color: #fff;
}

.deznav .metismenu > li > a:hover,
.deznav .metismenu > li > a:focus {
  color: inherit;
}

.deznav .copyright {
  margin: 30px 20px;
  padding: 0;
}

.deznav .copyright a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 40px;
  border-radius: 10px;
  transition: all ease-in-out 300ms;
}

.deznav .copyright a > span,
.deznav .copyright a > svg {
  color: var(--gray);
}

.deznav .copyright a:hover {
  background: var(--primary);
}

.deznav .copyright a span,
.deznav .copyright a svg {
  transition: all ease-in-out 300ms;
}

.deznav .copyright a:hover span,
.deznav .copyright a:hover svg {
  color: var(--white) !important;
}

.header-right .notification_dropdown .nav-link {
  background: transparent;
  padding: 5px;
}

.header-right .nav-link svg {
  width: 25px;
  height: 25px;
}

.gap-1 {
  gap: 10px;
}

.dashboard_bar .search-area {
  background: var(--white);
  filter: drop-shadow(0px 3.5px 5.5px rgba(0, 0, 0, 0.02));
  border-radius: 10px;
}

.search-area .input-group-text,
.search-area .form-control {
  background: transparent !important;
  padding-left: 5px;
}

.log-out {
  position: fixed !important;
  bottom: 0;
  background: var(--white);
  margin: 0 !important;
  padding-bottom: 15px !important;
}

.show:not(.menu-toggle) .log-out {
  width: 257px;
}

.log-out a {
  padding: 10px 13px !important;
}

.header-info p {
  color: var(--gray) !important;
}

.header-info h3 {
  font-size: 34px;
  font-weight: 600;
}

@media (max-width: 1300px) {
  .header-info h3 {
    font-size: 28px;
  }
}

@media (max-width: 650px) {
  .header-info h3 {
    font-size: 16px;
  }
}

.flag-account {
  display: flex;
  align-items: center;
}

.deznav .metismenu {
  padding-top: 30px;
  padding-bottom: 0px;
}

.search-area .input-group-text svg {
  width: 20px;
  height: 20px;
}

.transactions-date {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
}

.transactions-date svg {
  margin-top: -3px;
}

.color-gray {
  color: var(--gray);
}

.keep-safe {
  text-align: center;
  display: grid;
  align-items: center;
}

.keep-safe .card-body {
  padding: 55px 20px;
}

.keep-safe .image-box img {
  border-radius: 100px;
  background: var(--white);
  margin-bottom: 15px;
  box-shadow: 0 18px 40px rgba(112, 144, 176, 0.12);
}

.keep-safe .box-info h3 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.keep-safe .box-info p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--gray);
}

.keep-safe .box-info button {
  border-radius: 10px;
  padding: 12px 35px;
  border: 1px solid;
}

.card-balance {
  display: flex;
  align-items: center;
}

.info span {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: var(--gray);
}

.info h2 {
  margin: 0;
  font-weight: 600;
}

.info-icon button {
  border-radius: 10px;
  border: none;
  padding: 10px;
  display: grid;
}

.info-icon button svg {
  width: 30px;
  height: 30px;
}

.user-image-wrapper {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.user-image-wrapper .user-image {
  z-index: 1;
  cursor: pointer;
}

.user-image-wrapper .user-image.user-selected {
  z-index: 2;
}

.user-image-wrapper .user-image:not(:first-child) {
  margin-left: -10px;
}

.user-image-wrapper .user-image.user-selected img {
  border: 1px solid var(--primary);
}

.user-image-wrapper .all-user {
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  color: var(--warning);
  background: #ffd8bf;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--white);
}

.user-image-wrapper .all-user:not(:first-child) {
  margin-left: -10px;
}

.user-image-wrapper .user-image.user-selected .all-user {
  border: 1px solid var(--primary);
}

.custom-input .input-group-text {
  background: transparent;
}

.custom-input input,
.custom-input select {
  background: transparent;
  border: none;
  border-radius: 5px;
}

.custom-input {
  border: 1px solid #a0aec0;
  border-radius: 5px;
}

.border-black {
  border-color: var(--gray-dark) !important;
}

.border-gray {
  border-color: var(--gray) !important;
}

.text-gray {
  color: var(--gray);
}

.transfers-tabs {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  padding: 4px !important;
}
.beneficiary-tabs {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  background: rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  padding: 4px !important;
}
.swal-overlay {
  background: rgba(0, 145, 147, 0.54) !important;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.25) !important;
}
.swal-button,
.swal-button:hover,
.swal-button:active,
.swal-button:not([disabled]):hover {
  background: var(--primary);
}
.transfers-tabs li {
  width: 33.33%;
}

@media (max-width: 640px) {
  .transfers-tabs li {
    width: 100%;
  }
}

.beneficiary-tabs li {
  width: 50%;
}

.custom-tab-1 .transfers-tabs .nav-link {
  border: none !important;
  text-align: center;
  justify-items: center;
  height: 100%;
  align-content: space-between;
  display: grid;
  color: black;
}

.mm-wrapper {
  height: calc(100% - 59px);
  overflow: auto;
}

.custom-tab-1 .transfers-tabs .nav-link:focus,
.custom-tab-1 .transfers-tabs .nav-link:hover,
.custom-tab-1 .transfers-tabs .nav-link.active {
  background-color: #fff;
  justify-items: center;
  display: grid;
  height: 100%;
  align-content: space-between;
  border: none !important;
  border-radius: 10px;
  color: var(--primary);
  border-width: 0 !important;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.04));
}

.indexedStepText {
  position: absolute;
  font-size: 14px;
  width: max-content;
  margin: 50px 0 0 0;
  padding: 0;
}

.transactions-date {
  font-size: 14px;
}

.transfers-info-box {
  padding: 0 20px;
  display: block;
  border-radius: 15px !important;
  background-color: #fff;
  box-shadow: 0 3.5px 5.5px rgba(0, 0, 0, 0.02);
}
@media (max-width: 450px) {
  .transfers-info-box {
    padding: 0;
  }
}
.transfers-info-box tbody {
  width: 100%;
  display: inline-table;
}

.table-responsive-md tbody tr td:first-of-type {
  width: 70px;
}

.transfers-info-box tr td {
  border: none !important;
  background: transparent !important;
}

.quick-transactions {
}

.quick-transactions li {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.quick-transactions li span svg {
  vertical-align: middle;
}

.quick-transactions li span {
  color: var(--gray);
  font-weight: 300;
}

.transactions-filter-input {
  border-radius: 10px;
}

.transactions-filter-input input {
  border-radius: 10px 0 0 10px;
  border: none;
  height: 41px;
}

.transactions-filter-input .input-group-text {
  background: #fff;
  border-radius: 0 10px 10px 0;
  line-height: 12px;
  padding: 0 20px;
}

.transactions-filter-input .picker,
.transactions-filter-input .picker-input,
.transactions-filter-input .picker input {
  width: 100%;
  border-radius: 10px;
}

.transactions-filter-input .input-group-append {
  position: absolute;
  right: 0;
  height: 100%;
}

@media (max-width: 1200px) {
  .indexedStepText {
    font-size: 12px;
  }

  .indexedStepText.left {
    left: 0;
  }

  .indexedStepText.right {
    right: 0;
  }
}

@media (max-width: 500px) {
  .indexedStepText {
    font-size: 10px;
  }

  .transactions-date {
    font-size: 12px;
  }
}

.indexedStepText.accomplished {
  color: black;
}

.indexedStepText.null {
  color: var(--gray);
}

.transfers-form-wrapper {
  min-height: 679px;
}

.indexedStep {
  width: 10px !important;
  height: 10px !important;
}

.transfer-success,
.transfer-danger {
  padding: 40px;
}

.transfer-success .swal-icon,
.transfer-danger .swal-icon {
  margin-top: 0;
}

.transfer-success .swal-text {
  margin: 0;
  text-align: center;
  font-weight: 300;
  color: var(--gray);
}

.transfer-danger .swal-text {
  margin: 0;
  text-align: center;
  font-weight: 300;
  color: var(--danger);
}

.transfers-submit-btn,
.transfers-next-btn {
  max-width: 589px;
  width: 100%;
  margin: 0 auto;
}

.track-form {
  height: 30px;
}

.quick-transfer-form input {
  height: 41px;
}

.copied-text {
  position: absolute;
  left: -25px;
  top: -30px;
  background: var(--gray-dark);
  padding: 7px 12px;
  font-size: 12px;
  border-radius: 10px;
  color: var(--white);
}

.transactions-filter-input .range-picker-input {
  border: none;
}

.btn-processing {
  padding: 10px 30px !important;
  display: flex !important;
  align-items: center;
  gap: 15px;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.MuiFormLabel-root.Mui-focused {
  color: var(--primary) !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid var(--primary) !important;
}

.card-info-name {
  font-size: 12px;
  line-height: 16px;
}

.card-info-name.card-info-name-business {
  font-size: 14px;
}

.seperator-right {
  border-right: 2px solid #bdc7d3;
}

.seperator-bottom {
  border-bottom: 2px solid #bdc7d3;
}

.seperator-bottom-light {
  border-bottom: 1px solid #bdc7d3;
}

.manageCardArrow {
  width: auto;
  height: 15px;
  margin-left: auto;
  padding-right: 20px;
}

.manageCardText {
  margin: 0px 2rem;
}

.manageCardFlex {
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.manageCardFlex:hover {
  background-color: #f6fefc;
}

.limitContentFlex {
  align-items: center;
}

.card-text-font {
  font-family: Roboto;
}

.cardLinkText {
  color: #009193;
  font-weight: 400;
  cursor: pointer;
}

.cardDisplayActive {
  background: url('../images/backgroundCardActive.png');
  cursor: pointer;
}

.cardDispalayInactive {
  background: url('../images/backgroundCardInactive.png');
  cursor: pointer;
}

.cardDisplayCard {
  width: 100%;
}

.activeDot {
  height: 7px;
}

.carDescIcon {
  width: 64px;
  height: 64px;
}

@media (max-width: 1500px) {
  .card-info-name {
    font-size: 18px;
    line-height: 16px;
  }
  .card-bx .card-info .card-number {
    font-size: 18px;
  }
  .card-bx .card-info .iban_number {
    font-size: 18px;
  }
  .card-bx .card-info {
    padding: 15px 20px !important;
  }
  .card-bx .card-info .card-number svg {
    width: 12px !important;
  }
}
@media (max-width: 850px) {
  .card-info-name {
    font-size: 12px;
    line-height: 12px;
  }
  .card-bx .card-info .card-number {
    font-size: 12px;
  }
  .card-bx .card-info .card-number svg {
    width: 11px !important;
  }
}
@media (max-width: 767px) {
  .card-info-name {
    font-size: 20px;
  }
  .card-bx .card-info .card-number {
    font-size: 20px;
  }
  .card-bx .card-info {
    padding: 30px !important;
  }
  .card-bx .card-info .card-number svg {
    width: 14px !important;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1240px !important;
  }
}
@media (max-width: 469px) {
  .card-info-name {
    font-size: 11px;
  }
  .card-bx .card-info {
    padding: 15px 20px !important;
  }
  .card-bx .card-info .card-number {
    font-size: 11px;
  }
  .card-bx .card-info .card-number svg {
    width: 9px !important;
  }
}
@media (max-width: 375px) {
  .card-bx .card-info {
    padding: 15px 20px !important;
  }

  .card-bx .card-info .card-number {
    font-size: 11px;
  }
  .card-bx .card-info .card-number svg {
    width: 9px !important;
  }
  .card-info-name {
    font-size: 11px;
  }
}

.displaycard {
  width: 1px;
  height: 1px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  -webkit-perspective: 900px;
  -moz-perspective: 900px;
  perspective: 900px;
}

.cardModal {
  width: 0px;
  height: 0px;
}

.card__part {
  box-shadow: 1px 1px #aaa3a3;
  top: 1;
  position: absolute;
  z-index: 1000;
  left: 1;
  display: inline-block;
  width: 550px;
  height: 290px;
  background-image: url('../images/card.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.card__front {
  padding: 18px;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
}

.card__back {
  padding: 18px 0;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
}

.card__black-line {
  margin-top: 5px;
  height: 38px;
  background-color: #303030;
}

.card__logo {
  height: 16px;
}

.card__front-logo {
  position: absolute;
  top: 18px;
  right: 18px;
}

.card__square {
  border-radius: 5px;
  height: 30px;
}

.card_numer {
  margin-top: 165px;
  height: 20px;
  margin-left: 25px;
}

.cardDataContent {
  color: white;
}
.cardData {
  margin-top: -20px;
}

.cardHolderName {
  margin-left: 20px;
}

.card__space-50 {
  width: 50%;
  float: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.card__space-25 {
  width: 25%;
  float: right;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}

.card__label {
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 1px;
}

.card__info {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.cvv__info {
  margin-top: 0px;
  margin-right: 190px;
}

.show_card_details {
  position: absolute;
  z-index: 1000;
  right: -16px;
  top: -12px;
  border: 0;
  box-shadow: 0px 4px 8px -4px rgba(16, 26, 33, 0.2);
  width: 32px;
  height: 32px;
  background: #3f3f3e;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
}

.eye_open {
  opacity: 0;
  height: 0;
}

.eye_close {
  opacity: 1;
}

@keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

@-webkit-keyframes loader {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
