.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  color: var(--gray-black);
  background: var(--bg-color);
  position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: 0.25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.cal-col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.cal-col-start {
  justify-content: flex-start;
  text-align: left;
}

.cal-col-center {
  justify-content: center;
  text-align: center;
}

.cal-col-end {
  justify-content: flex-end;
  text-align: right;
}

.calendar-container {
  overflow-x: scroll;
}

.calendar {
  display: block;
  position: relative;
  width: 100%;
  min-width: 281px;
  padding-bottom: 20px;
  padding-top: 15px;
  border-bottom: 0.8px solid rgba(160, 174, 192, 0.7);
  background: var(--neutral-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.416667px;
  color: #253342;
  padding: 0.75em 0;
}

.calendar .days .restday {
  color: #a0aec0;
}

.calendar .body .cell {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.416667px;
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body span {
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar .body .paymentday span {
  background: #d4f9f2;
  border-radius: 50%;
}
.calendar .body .eventday {
  position: relative;
}
.calendar .body .dots {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar .body .dots .dot {
  display: flex;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 2px;
  background-color: #009193;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}
.calendar .body .cell.today .bg {
  color: #ff1a79;
  opacity: 0.05;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.calendar-due {
  display: flex;
  align-items: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  width: 100%;
  letter-spacing: -0.02em;
  padding-top: 14.5px;
  color: rgba(0, 0, 0, 0.8);
}
.calendar-due span {
  width: 19px;
  height: 19px;
  display: flex;
  border-radius: 50%;
  background-color: #d4f9f2;
  margin-right: 8px;
}

.invoice-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: left;
  letter-spacing: -0.02em;
  color: #363740;
  margin-bottom: 20px;
}

.invoice-group {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 23px;
}
.invoice-group > div:first-child {
  border-right: 0.7px solid rgba(160, 174, 192, 0.7);
  padding-left: 0;
}

.invoice-item {
  display: flex;
  align-items: center;
  padding-left: 50px;
  width: 50%;
}

@media (max-width: 485px) {
  .invoice-group {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 0;
  }
  .invoice-group > div:first-child {
    border-right: none;
  }
  .invoice-item {
    width: 100%;
    margin: 10px 0px;
    padding: 0;
  }
}

.invoice-item img {
  margin-right: 25px;
}
.invoice-item p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: -0.02em;
  color: #a0aec0;
}
.invoice-item p span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #363740;
}

.integration-table {
  width: 100%;
}

.integration-table td {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.8);
}
.integration-table th {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #676767;
}
.integration-table td,
.integration-table th {
  padding: 10px 0;
}
.integration-table td:not(.slack) p {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  margin-bottom: 0;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.8);
}
.integration-table td:not(.slack) span {
  color: #009193;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  background: rgba(212, 249, 242, 0.5);
  border-radius: 10px;
  padding: 1px 8px;
}

.apps-group {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.8);
}
.apps-group > div {
  width: 50%;
  box-sizing: border-box;
  padding: 0 12px;
  min-width: 320px;
}

@media (max-width: 800px) {
  .apps-group > div {
    width: 100%;
  }
}
.apps-group img {
  width: 44px;
  height: 44px;
  margin-right: 24px;
}
.apps-group p {
  padding-left: 70px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.7);
}
.apps-group span {
  background: #009193;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding: 5px 12px;
}

.apps-group .connect {
  background-color: #d4f9f2;
  color: #009193;
}

.apps-group .connected {
  border: 1px solid #009193;
  border-radius: 15px;
}

.apps-group span img {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}

.integration-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #363740;
}

.integration-table th {
  white-space: nowrap;
  padding-right: 5px;
}

.integration-table td {
  white-space: nowrap;
  line-height: 10px;
  padding-right: 5px;
}

@media (max-width: 1430px) {
  .invoice-history td:first-child {
    padding-left: 15px;
    padding-right: 15px;
  }
  .invoice-history th:first-child {
    padding-left: 15px;
    padding-right: 13px;
  }
  .earning-chart {
    min-width: 300px;
  }
}

@media (min-width: 1300px) and (max-width: 1430px) {
  .business-card {
    min-width: 280px;
    padding: 30px 5px;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .earning-padding {
    margin-bottom: 30px;
  }
}

@media (min-width: 1600px) {
  .earning-padding {
    margin-bottom: 50px;
  }
}
